import React from "react";

import airLeak from '../assets/logo/air_leak.png'
import investigate from '../assets/logo/investigate.png'
import professional from '../assets/logo/professional.png'
import { useTranslation } from 'react-i18next';


function Objectives() {

    const { t, i18n } = useTranslation();

    return (
        <div className="card radius-0  pt-6 pb-4">
            <p className="is-size-1 has-text-centered has-text-black pt-4 text-underlined">{t('objectives_title')}</p>
            <div className="columns m-6">
                <div className="column has-text-centered">
                    <img className="m-6" src={airLeak}></img>
                    <p>{t('air_leak')}</p>
                </div>

                <div className="column has-text-centered">
                    <img className="m-6" src={investigate}></img>
                    <p>{t('detection')}</p>
                </div>

                <div className="column has-text-centered">
                    <img className="m-6" src={professional}></img>
                    <p>{t('profesional_use')}</p>
                </div>
            </div>
        </div>
    )
}

export default Objectives;
