import NavBar from '../components/navBar'
import Footer from '../components/footer'
import Loading from '../components/loading'

import React, { useState, useEffect } from 'react';

import useStore from '../data/useStore';

import { useTranslation } from 'react-i18next';

function MaterialCard(props) {
    const picture = `${process.env.PUBLIC_URL}/material/${props.tool.picture}`

    return (
        <div className="column is-one-third" key={props.index}>
            <div className="card">
                <div className="has-text-centered">
                    <figure className="m-5 image is-inline-block">
                        <img style={{ width: '200px', height: '200px' }} className="blue-border is-rounded" src={picture} alt={props.index} />
                    </figure>
                </div>
                <div className="card-content">
                    <div className="content has-text-centered" id="pre-line">
                        <p className="is-size-5 has-text-weight-bold">{props.tool.title}</p>
                        <p className="is-size-5">{props.tool.description}</p>
                        <p className="is-size-6 is-italic">{props.tool.origin}</p>
                    </div>
                </div>
            </div>
        </div >
    );
}

function Material() {

    const { t, i18n } = useTranslation();

    const fileStore = useStore();
    const [materialFile, setMaterialFile] = useState(null);

    let camera = [];
    let compteur = [];
    let N95 = [];
    let language = i18n.language;


    useEffect(() => {
        const savedMaterial = localStorage.getItem('material');
        if (savedMaterial) {
            setMaterialFile(JSON.parse(savedMaterial));
        } else {
            try {
                const material = fileStore.getFile('material');
                if (material) {
                    const materialString = JSON.stringify(material, null, 2);
                    localStorage.setItem('material', materialString);
                    setMaterialFile(material);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }, [fileStore]);

    if (!materialFile) {
        return <Loading/>
    } else {
        materialFile[language]['camera'].forEach((tool, index) => {
            camera.push(<MaterialCard tool={tool} index={index} key={index}/>);
        });

        materialFile[language]['particles'].forEach((tool, index) => {
            compteur.push(<MaterialCard tool={tool} index={index} key={index}/>);
        });

        materialFile[language]['N95'].forEach((tool, index) => {
            N95.push(<MaterialCard tool={tool} index={index} key={index}/>);
        });

        return (
            <>
                <section className="section primary-color">
                    <NavBar />
                </section>

                <div className="card radius-0  primary-color pt-2 pb-2">
                    <div className="is-size-1 has-text-centered has-text-weight-bold pt-6 m-6 has-text-white is-relative">{t('material_title')}</div>
                    <div className="is-size-4 has-text-centered m-4 has-text-white is-relative pb-6">
                        {t('material_desc')}</div>
                </div>

                <div className="mt-6"><br></br>

                    <div className="is-size-1 has-text-centered has-text-white primary-color">{t('camera')}</div>
                    <div className="columns is-multiline is-justify-content-center m-6">
                        {camera}
                    </div>

                    <div className="is-size-1 has-text-centered has-text-white primary-color">{t('particles')}</div>
                    <div className="columns is-multiline is-justify-content-center m-6">
                        {compteur}
                    </div>


                    <div className="is-size-1 has-text-centered has-text-white primary-color">{t('respirator')}</div>
                    <div className="columns is-multiline is-justify-content-center m-6">
                        {N95}
                    </div>

                </div>

                <Footer />
            </>
        )
    }
}

export default Material;
