import NavBar from '../components/navBar'
import Footer from '../components/footer'

function Contact() {
    return (
        <>
            <section className="section primary-color">
                <NavBar />
            </section>
            <div className="card radius-0  primary-color pt-2 pb-2">
                <div className="is-size-1 has-text-centered has-text-weight-bold pt-6 m-6 has-text-white is-relative">Meet our team</div>
                <div className="is-size-4 has-text-centered m-4 has-text-white is-relative pb-6">
                    Explore our team of UQAR and Laval University students, along with researchers, studying air leakage during APR use for improved safety.
                </div>
            </div>
            <Footer />

        </>
    )
}

export default Contact;
