import React from "react";

import participants from '../assets/logo/participants.png'
import apr from '../assets/logo/apr.png'
import fittest from '../assets/logo/fittest.png'
import video from '../assets/logo/video.png'

import { useTranslation } from 'react-i18next';

function Characteristics() {

    const { t, i18n } = useTranslation();

    return (
        <div className="card radius-0  pt-6 pb-4 has-background-light">
            <p className="is-size-1 has-text-centered has-text-black pt-4 text-underlined">{t('characteristics_title')}</p>
            <div id="pre-line" className="columns m-6">
                <div className="column has-text-centered">
                    <img className="m-6 pb-3" src={participants}></img>
                    <p>{t('participants')}</p>
                </div>

                <div className="column has-text-centered">
                    <img className="m-6" src={apr}></img>
                    <p>{t('n95_tested')}</p>
                </div>

                <div className="column has-text-centered">
                    <img className="m-6 pb-3" src={fittest}></img>
                    <p>{t('fit_test')}</p>
                </div>
                <div className="column has-text-centered">
                    <img className="m-6 pb-3" src={video}></img>
                    <p>{t('videos')}</p>
                </div>
            </div>
        </div>
    )
}

export default Characteristics;