import uqar from '../assets/partners/uqar.png'
import mitacs from '../assets/partners/mitacs.webp'
import laval from '../assets/partners/laval.png'
import irsst from '../assets/partners/irsst.png'

import { useTranslation } from 'react-i18next';

function Partners() {
    const { t, i18n } = useTranslation();

    return (
        <div className="card radius-0 pt-4 pb-4">
            <p className="is-size-1 has-text-centered has-text-black pt-4 text-underlined">{t('partners_title')}</p>
            <div className="columns m-6">
                <div className="column has-text-centered">
                    <img className="m-6 partners-image-size" src={uqar}></img>
                </div>

                <div className="column has-text-centered">
                    <img className="m-6 partners-image-size" src={laval}></img>
                </div>

                <div className="column has-text-centered pt-5">
                    <img className="m-6 partners-image-size" src={irsst}></img>
                </div>

                <div className="column has-text-centered pt-5">
                    <img className="m-6 mitacs-image-size" src={mitacs}></img>
                </div>
            </div>
        </div>
    )
}

export default Partners;