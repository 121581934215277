import NavBar from '../components/navBar'
import Footer from '../components/footer'
import Loading from '../components/loading'

import React, { useState, useEffect } from 'react';

import useStore from '../data/useStore';

import { useTranslation } from 'react-i18next';


function paginate(array, pageSize) {

    return array.reduce((acc, value, index) => {
        const pageIndex = Math.floor(index / pageSize);
        if (!acc[pageIndex]) {
            acc[pageIndex] = [];
        }
        acc[pageIndex].push(value);
        return acc;
    }, []);
}

function News() {

    const { t, i18n } = useTranslation();

    const [currentPage, setCurrentPage] = useState(0);
    const [previousPage, setPreviousPage] = useState(-1);
    const [nextPage, setNextPage] = useState(1);
    const fileStore = useStore();
    const [newsFile, setNewsFile] = useState(null);

    let news = [];
    let maxPage = 0;
    let language = i18n.language;

    useEffect(() => {
        const savedNews = localStorage.getItem('news');
        if (savedNews) {
            setNewsFile(JSON.parse(savedNews));
        } else {
            try {
                const news = fileStore.getFile('news');
                if (news) {
                    const newsString = JSON.stringify(news, null, 2);
                    localStorage.setItem('news', newsString);
                    setNewsFile(news);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }, [fileStore]);

    if (!newsFile) {
        return <Loading/>
    } else {
        newsFile[language].forEach((item, index) => {
            news.push(
                <div className="timeline-item" key={index}>
                    <div className="timeline-marker"></div>
                    <div className="timeline-content">
                        <p className="heading has-text-weight-bold has-text-primary-color">{item.date}</p>
                        <p>{item.title}</p>
                    </div>
                </div>
            );
        });

        news = paginate(news, 10);
        maxPage = news.length;

        return (
            <>
                <section className="section primary-color">
                    <NavBar />
                </section>
                <div className="card radius-0  primary-color pt-2 pb-2">
                    <div className="is-size-1 has-text-centered has-text-weight-bold pt-6 m-6 has-text-white is-relative">{t('news_title')}</div>
                    <div className="is-size-4 has-text-centered m-4 has-text-white is-relative pb-6">{t('news_desc')}
                    </div>
                </div>

                <br></br>

                <div className="is-flex is-justify-content-center">
                    <div className="timeline is-centered">
                        <header className="timeline-header">
                        </header>
                        {news[currentPage]}
                        <header className="timeline-header">
                        </header>
                    </div>
                </div>


                <nav className="pagination is-centered" role="navigation" aria-label="pagination">
                    <button disabled={previousPage < 0 ? true : false} className="m-6 pagination-previous" onClick={() => {
                        setNextPage(currentPage);
                        setPreviousPage(currentPage - 2);
                        setCurrentPage(currentPage - 1);
                    }}>{t('previous')}</button>

                    <button disabled={nextPage > maxPage - 1 ? true : false} className="m-6 pagination-next" onClick={() => {
                        setPreviousPage(currentPage);
                        setNextPage(currentPage + 2);
                        setCurrentPage(currentPage + 1);
                    }} >{t('next')}</button>

                    <ul className="pagination-list">
                        <li><button className="pagination-link" aria-label={"0"} onClick={() => {
                            setPreviousPage(-1);
                            setNextPage(1);
                            setCurrentPage(0);
                        }}>{0}</button></li>
                        <li><span className="pagination-ellipsis">&hellip;</span></li>
                        <li>
                            <button className="pagination-link is-current pagination-color"
                                aria-label={currentPage - 1}
                                aria-current="page"
                            >{currentPage}</button>
                        </li>
                        <li><span className="pagination-ellipsis">&hellip;</span></li>
                        <li><button className="pagination-link" aria-label={"maxPage - 1"} onClick={() => {
                            setPreviousPage(maxPage - 2);
                            setNextPage(maxPage);
                            setCurrentPage(maxPage - 1);
                        }}>{maxPage - 1}</button></li>
                    </ul>
                </nav>

                <Footer />

            </>
        )
    }
}

export default News;
