import React from "react";

function Footer() {
  return (
    <footer className="footer p-6 has-text-white primary-color">
      <div className="content has-text-centered">
        <p>
        <strong className="has-text-white">Copyright © </strong>2024 <strong className="has-text-white">Université Laval</strong>, <strong className="has-text-white">UQAR</strong> & <strong className="has-text-white">IRSST</strong><br/>Made by <strong className="has-text-white">Inès Maaroufi</strong>
        </p>
      </div>
    </footer>
  )
}

export default Footer;
