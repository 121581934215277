import create from 'zustand';

const useStore = create((set) => ({
  files: {},
  setFile: (fileName, jsonData) => set((state) => ({
    files: {
      ...state.files,
      [fileName]: jsonData,
    },
  })),
  getFile: (fileName) => {
    const file = useStore.getState().files[fileName];
    return file || null;
  },
}));

export default useStore;