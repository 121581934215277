import React, { useState } from 'react';

import { NavLink } from "react-router-dom";
import logo from '../assets/logo/logo.png'

import { useTranslation } from 'react-i18next';

import 'boxicons';

function NavBar() {


    const { t, i18n } = useTranslation();
    const [lang , setLang] = useState('fr')

    const [isActive, setisActive] = React.useState(false);
    const [isDropdown, setisDropdown] = React.useState(false);

    const routes = [t('home'), t('team'), t('contributions'), t('news'), t('material'), t('download')];
    const names = ["Home", "Team", "Contributions", "News", "Material", "Download"];

    let routesList = [];

    function changeLanguage(event) {
        setLang(event.target.value);
        i18n.changeLanguage(lang);
    };


    routes.forEach((route, index) => {
        routesList.push(
            <div className="m-2 ml-6 navbar-item is-size-5" key={index}>
                <NavLink
                    to={names[index] === "Home" ? "/" : ("/" + names[index])}
                    className={`${isDropdown ? "has-text-black" : "has-text-white"}`}
                >
                    {route}
                </NavLink>
            </div>
        );
    });

    return (
        <>
            <nav className="navbar is-transparent is-fixed-top primary-color">
                <div className="navbar-brand m-2">
                    <a className="navbar-item is-hoverable" href="/">
                        <figure>
                            <img className="logo-max-size" alt="logo" src={logo}></img>
                        </figure>
                    </a>
                    <button className={`navbar-burger burger ${isActive ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={() => {
                        setisActive(!isActive);
                        setisDropdown(!isDropdown);
                    }}>
                        <span className="has-text-white"></span>
                        <span className="has-text-white"></span>
                        <span className="has-text-white"></span>
                    </button>
                </div>

                <div id="navbarBasicExample" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
                    <div key="navlist" className="navbar-start">
                        {routesList}
                    </div>

                    <div className="navbar-end navbar-item">
                        <div className="control has-icons-left">
                            <div className="select is-medium">
                                <select value={lang} onChange={changeLanguage}>
                                    <option value="fr" defaultValue>{t('english')}</option>
                                    <option value="en">{t('french')}</option>
                                </select>
                            </div>
                            <span className="icon is-medium is-left ">
                                <box-icon name='globe'></box-icon>
                            </span>
                        </div>
                    </div>
                </div>
            </nav >
            <hr className="is-relative white-background m-0" />
        </>
    )
};

export default NavBar;
