
import Person from '../components/person'
import NavBar from '../components/navBar';
import Footer from '../components/footer'

import uqar from '../assets/partners/uqar.png'
import mitacs from '../assets/partners/mitacs.webp'
import laval from '../assets/partners/laval.png'
import irsst from '../assets/partners/irsst.png'

import { useTranslation } from 'react-i18next';

function Team() {

    const { t, i18n } = useTranslation();

    return (
        <>
            <section className="section primary-color">
                <NavBar />
            </section>
            <div className="card radius-0  primary-color pt-1 pb-4">
                <div className="is-size-1 has-text-centered has-text-weight-bold pt-6 m-6 has-text-white is-relative">{t('team_title')}</div>
                <div className="is-size-4 has-text-centered m-4 has-text-white is-relative pb-6">
                {t('team_desc')}
                </div>
            </div>
            <div className="columns mt-2 ml-2 mr-2 mb-0">
                <div className="column has-text-centered">
                    <img className="m-6 partners-image-size" src={uqar}></img>
                </div>

                <div className="column has-text-centered">
                    <img className="m-6 partners-image-size" src={laval}></img>
                </div>

                <div className="column has-text-centered pt-5">
                    <img className="m-6 partners-image-size" src={irsst}></img>
                </div>
            </div>
            <Person />
            <Footer/>

        </>
    )
}

export default Team;

