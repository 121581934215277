import NavBar from '../components/navBar'
import Footer from '../components/footer'
import { useTranslation } from 'react-i18next';

import notFound from '../assets/logo/not_found.png';



function NotFound() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <section className="section primary-color">
                <NavBar />
            </section>
            <div className="card radius-0  primary-color pt-2 pb-2">
                <div className="is-size-1 has-text-centered has-text-weight-bold pt-6 m-6 has-text-white is-relative">{t('not_found_title')}</div>
                <div className="is-size-4 has-text-centered m-4 has-text-white is-relative pb-6">{t('not_found_desc')}</div>
            </div>
            <div class="is-flex is-justify-content-center">
                <img className="m-2" src={notFound}></img>
            </div>
            <Footer />

        </>
    )
}

export default NotFound;