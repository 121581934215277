import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gif from '../assets/gifs/1.gif'
import gif1 from '../assets/gifs/2.gif'
import gif2 from '../assets/gifs/3.gif'
import gif3 from '../assets/gifs/4.gif'


export default function SimpleSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };
  return (
    <Slider {...settings}>
      <div>
        <img className="image-slider" alt="gif3" src={gif} />
      </div>
      <div>
        <img className="image-slider" alt="gif2" src={gif3} />
      </div>
      <div>
        <img className="image-slider" alt="gif1" src={gif2} />
      </div>
      <div>
        <img className="image-slider" alt="gif" src={gif1} />
      </div>
    </Slider>
  );
}
