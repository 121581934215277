import NavBar from '../components/navBar'
import Footer from '../components/footer'
import Loading from '../components/loading'

import React, { useState, useEffect } from 'react';

import useStore from '../data/useStore';

import { useTranslation } from 'react-i18next';

function CitationCard(props) {
    return (
        <div className='is-flex is-justify-content-center mt-4 mb-4 column is-half' key={props.index}>
            <div className="card has-text-weight-bold has-background-light m-0 is-flex is-align-items-center">
                <div className='m-6'>{props.person.apa}</div>
                <button className="button primary-color is-pulled-right mx-4" onClick={() => navigator.clipboard.writeText(props.person.bibtext)}>
                    <span className="icon is-small">
                        <box-icon color="white" name='copy-alt'></box-icon>
                    </span>
                </button>
            </div>
        </div>
    )
}

function Contributions() {

    const { t } = useTranslation();
    const fileStore = useStore();
    const [contributionsFile, setContributionsFile] = useState(null);

    let conferences = [];
    let newspapers = [];
    let memoires = [];

    useEffect(() => {
        const savedContributions = localStorage.getItem('contributions');
        if (savedContributions) {
            setContributionsFile(JSON.parse(savedContributions));
        } else {
            try {
                const contributions = fileStore.getFile('contributions');
                if (contributions) {
                    const contributionsString = JSON.stringify(contributions, null, 2);
                    localStorage.setItem('contributions', contributionsString);
                    setContributionsFile(contributions);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }, [fileStore]);

    if (!contributionsFile) {
        return <Loading/>
    } else {
        contributionsFile['conferences'].forEach((person, index) => {
            conferences.push(<CitationCard person={person} index={index} key={index}/>);
        });
        contributionsFile['newspapers'].forEach((person, index) => {
            newspapers.push(<CitationCard person={person} index={index} key={index}/>);
        });

        contributionsFile['memoires'].forEach((person, index) => {
            memoires.push(<CitationCard person={person} index={index} key={index}/>);
        });

        return (
            <>
                <section className="section primary-color">
                    <NavBar />
                </section>
                <div className="card radius-0  primary-color pt-2 pb-2">
                    <div className="is-size-1 has-text-centered has-text-weight-bold pt-6 m-6 has-text-white is-relative">{t('contribution_title')}</div>
                    <div className="is-size-4 has-text-centered m-4 has-text-white is-relative pb-6">{t('contribution_desc')}
                    </div>
                </div>

                <br></br>

                <div>
                    <br></br>

                    <div className="is-size-1 has-text-centered has-text-white primary-color">{t('conferences')}</div>
                    <div className="columns is-multiline is-justify-content-center m-6">
                        {conferences}
                    </div>

                    <div className="is-size-1 has-text-centered has-text-white primary-color">{t('journal')}</div>
                    <div className="columns is-multiline is-justify-content-center m-6">
                        {newspapers}
                    </div>

                    <div className="is-size-1 has-text-centered has-text-white primary-color">{t('briefs')}</div>
                    <div className="columns is-multiline is-justify-content-center m-6">
                        {memoires}
                    </div>

                </div>

                <Footer/>
            </>
        )
    }
}

export default Contributions;
