import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: {
            //NAVBAR
            "home": "Home",
            "team": "Team",
            "contributions": "Contributions",
            "overview": "Overview",
            "news": "News",
            "material": "Material",
            "download": "Download",
            // HOME
            "home_title": "What is SafeRespirator ?",
            "home_desc": "Welcome to our research project featuring students from UQAR and Laval University, alongside researchers from IRSST, investigating air leakage during APR use using AI and infrared camera technology.",
            // CHARACTERISTICS
            "characteristics_title": "Characteristics",
            "participants": "62 participants tested :\n36 women\n26 men",
            "n95_tested": "4 different N95 FFRs tested by each participant\n7 different N95 FFRs tested In totality",
            "fit_test": "~500 Fit Tests Performed\n+1000 IR / RGB Videos",
            "videos": "1920x1080 RGB Videos\n1280x1024 IR Videos",
            // OBJECTIVES
            "objectives_title": "Objectives",
            "air_leak": "Develop a method to locate and quantify a N95 leakage",
            "detection": "Make this leak detection method automatic",
            "profesional_use": "Implement this method in a station for profesional use",
            // SAMPLE
            "sample_title": "Sample vizualisation",
            "top": "Top",
            "front": "Front",
            "left": "Left",
            "right": "Right",
            "without": "Without",
            // PARTNERS
            "partners_title": "Our Partners",
            //TEAM
            "team_title": "Meet our team",
            "team_desc": "Explore our team of UQAR and Laval University students, along with researchers, studying air leakage during APR use for improved safety.",
            "researchers": "Researchers",
            "professors": "Professors",
            "students": "Students",
            //NEWS
            "news_title": "News about us",
            "news_desc": "Dive into the latest updates and developments in our research.",
            "previous": "Previous",
            "next": "Next",
            //MATERIAL
            "material_title": "Discover our material",
            "material_desc": "The tools we used to detect and record air leaks.",
            "camera": "Cameras",
            "particles": "Particles Control",
            "respirator": "N95 Respirators",
            //DOWNLOAD
            "download_title": "Download our database",
            "download_desc": "You must agree to the following terms.",
            "dataset_usage": "Dataset usage rules",
            "dataset_access": "Dataset access",
            "contact_instructions": "If you want to download the SafeRespirator database or more informations, please send email to : ",
            "download_license": "Download license agreement",
            "download_image": "Download image use consent",
            "download_citation": "Citation",
            "citation_desc": "Please cite the following paper if SafeRespirator is useful to your research :",
            // Others
            "loading": "Loading ...",
            "loading_desc": "Please return to the page",
            "english": "English",
            "french": "French",
            // CONTRIBUTIONS
            "contribution_title": "Our contributions",
            "contribution_desc": "Explore our articles and references on our project",
            "conferences": "Conferences",
            "briefs": "Briefs",
            "journal": "Journal",
            //NOT FOUND
            "not_found_title": "404 NOT FOUND",
            "not_found_desc": "The page you trying to reach dosen't exist.",
            //IN CONSTRUCTION
            "construction_title": "Page under construction",
            "construction_desc": "The page you're trying to reach will soon be available."
        }
    },
    fr: {
        translation: {
            //NAVBAR
            "home": "Accueil",
            "team": "Équipe",
            "contributions": "Contributions",
            "overview": "Vue d'ensemble",
            "news": "Actualités",
            "material": "Matériel",
            "download": "Téléchargement",
            // HOME
            "home_title": "Qu'est-ce que SafeRespirator ?",
            "home_desc": "Découvrez notre projet de recherche auquel participent des étudiants de l'UQAR et de l'Université Laval, en association avec des chercheurs de l'IRSST, qui étudient les fuites d'air lors de l'utilisation de l'APR à l'aide de l'IA et d'une caméra infrarouge.",
            // CHARACTERISTICS
            "characteristics_title": "Caractéristiques",
            "participants": "62 participants testés : \n36 femmes\n26 hommes",
            "n95_tested": "4 FFR N95 différents testés par chaque participant\n7 FFR N95 différents testés au total",
            "fit_test": "~500 Fit Tests réalisés\n+1000 vidéos IR / RGB",
            "videos": "1920x1080 vidéos RGB\n1280x1024 vidéos IR",
            // OBJECTIVES
            "objectives_title": "Objectives",
            "air_leak": "Développer une méthode pour localiser et quantifier une fuite de N95",
            "detection": "Rendre cette méthode de détection des fuites automatique",
            "profesional_use": "Mettre en œuvre cette méthode dans une station à usage professionnel",
            // SAMPLE
            "sample_title": "Exemple de visualisation",
            "top": "Haut",
            "front": "Face",
            "left": "Gauche",
            "right": "Droite",
            "without": "Sans",
            // PARTNERS
            "partners_title": "Nos partenaires",
            //TEAM
            "team_title": "Rencontrez notre équipe",
            "team_desc": "Découvrez notre équipe d'étudiants de l'UQAR et de l'Université Laval, ainsi que des chercheurs, qui étudient les fuites d'air lors de l'utilisation de l'APR afin d'améliorer la sécurité.",
            "researchers": "Chercheurs",
            "professors": "Professeurs",
            "students": "Étudiants",
            //NEWS
            "news_title": "Nos actualités",
            "news_desc": "Plongez dans les dernières mises à jour et les derniers développements de notre recherche.",
            "previous": "Précédent",
            "next": "Suivant",
            //MATERIAL
            "material_title": "Découvrez notre matériel",
            "material_desc": "Les outils utilisés pour détecter et enregistrer les fuites d'air.",
            "camera": "Caméras",
            "particles": "Contrôle de particules",
            "respirator": "Masques N95",
            //DOWNLOAD
            "download_title": "Télécharger notre base de données",
            "download_desc": "Vous devez accepter les conditions suivantes :",
            "dataset_usage": "Règles d'utilisation des ensembles de données",
            "dataset_access": "Accès aux données",
            "contact_instructions": "Si vous souhaitez télécharger la base de données de SafeRespirator ou obtenir de plus amples informations, veuillez envoyer un courrier électronique à : ",
            "download_license": "Télécharger la licence d'accès",
            "download_image": "Télécharger le document pour l'autorisation d'images",
            "download_citation": "Citation",
            "citation_desc": "Veuillez citer l'article suivant si SafeRespirator est utile à votre recherche :",
            // Others
            "loading": "Chargement...",
            "loading_desc": "Veuillez revenir sur la page",
            "english": "Anglais",
            "french": "Français",
            // CONTRIBUTIONS
            "contribution_title": "Nos contributions",
            "contribution_desc": "Explorez nos articles et références sur notre projet",
            "conferences": "Conférences",
            "briefs": "Mémoires",
            "journal": "Articles",
            //NOT FOUND
            "not_found_title": "404 NOT FOUND",
            "not_found_desc": "La page que vous essayez d'atteindre n'existe pas.",
            //IN CONSTRUCTION
            "construction_title": "Page en construction",
            "construction_desc": "La page que vous essayez d'atteindre sera bientôt disponible."
        }
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en',
        fallbackLng: 'fr',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;