import React from "react";

import left_ir from '../assets/gifs/left_ir.gif'
import right_ir from '../assets/gifs/right_ir.gif'
import front_ir from '../assets/gifs/front_ir.gif'
import top_ir from '../assets/gifs/top_ir.gif'
import without_ir from '../assets/gifs/without_ir.gif'

import left_rgb from '../assets/gifs/left_rgb.gif'
import right_rgb from '../assets/gifs/right_rgb.gif'
import front_rgb from '../assets/gifs/front_rgb.gif'
import top_rgb from '../assets/gifs/top_rgb.gif'
import without_rgb from '../assets/gifs/without_rgb.gif'

import { useTranslation } from 'react-i18next';

function Sample() {
    const [tabNumber, setTabNumber] = React.useState(0);
    const [profilNumber, setProfilNumber] = React.useState(0);

    const gifs = [
        [front_ir, top_ir, left_ir, right_ir, without_ir], // IR
        [front_rgb, top_rgb, left_rgb, right_rgb, without_rgb], // RGB
    ]

    const { t, i18n } = useTranslation();

    return (
        <div className="card radius-0 pt-6 pb-4 has-background-light">
            <p className="is-size-1 has-text-centered has-text-black pt-4 pb-6 text-underlined">{t('sample_title')}</p>

            <div className="tabs is-large is-centered">
                <ul className="remove-border">
                    <li key={"ir"} className={`${tabNumber === 0 ? "is-active" : ""}`}><a onClick={() => { setTabNumber(0) }} className="mb-4">IR</a>
                    </li>
                    <li key={"rgb"} className={`${tabNumber === 1 ? "is-active" : ""}`}><a onClick={() => { setTabNumber(1) }} className="mb-4">RGB</a>
                    </li>
                </ul>
            </div>
            <div className="is-flex is-justify-content-center mb-6">
                <img className="" src={gifs[tabNumber][profilNumber]} />
            </div>
            <div className="tabs is-large is-centered pb-6">
                    <ul className="remove-border">
                        <li className={`${profilNumber === 0 ? "is-active" : ""}`}><a onClick={() => { setProfilNumber(0) }}>{t('front')}</a></li><br />
                        <li className={`${profilNumber === 1 ? "is-active" : ""}`}><a onClick={() => { setProfilNumber(1) }}>{t('top')}</a></li><br />
                        <li className={`${profilNumber === 2 ? "is-active" : ""}`}><a onClick={() => { setProfilNumber(2) }}>{t('left')}</a></li><br />
                        <li className={`${profilNumber === 3 ? "is-active" : ""}`}><a onClick={() => { setProfilNumber(3) }}>{t('right')}</a></li><br />
                        <li className={`${profilNumber === 4 ? "is-active" : ""}`}><a onClick={() => { setProfilNumber(4) }}>{t('without')}</a></li><br />
                    </ul>
                </div>
        </div>
    )
}

export default Sample;