import NavBar from '../components/navBar'
import Footer from '../components/footer'
import { useTranslation } from 'react-i18next';

import loading from '../assets/logo/loading.png';



function Loading() {
    const { t } = useTranslation();

    return (
        <>
            <section className="section primary-color">
                <NavBar />
            </section>
            <div className="card radius-0  primary-color pt-2 pb-2">
                <div className="is-size-1 has-text-centered has-text-weight-bold pt-6 m-6 has-text-white is-relative">{t('loading')}</div>
                <div className="is-size-4 has-text-centered m-4 has-text-white is-relative pb-6">{t('loading_desc')}</div>
            </div>
            <div className="is-flex is-justify-content-center">
                <img className="m-2" alt="loading" src={loading}></img>
            </div>
            <Footer />
        </>
    )
}

export default Loading;