import NavBar from '../components/navBar'
import Footer from '../components/footer'
import Loading from '../components/loading'

import React, { useState, useEffect } from 'react';

import useStore from '../data/useStore';
import { useTranslation } from 'react-i18next';

import 'boxicons';

import 'bulma/css/bulma.css';
import 'bulma-tooltip/dist/css/bulma-tooltip.min.css';

function Download() {

    const { t, i18n } = useTranslation();

    let language = i18n.language;
    let rules = [];
    let access = [];
    let fields = [];

    const fileStore = useStore();
    const [downloadFile, setDownloadFile] = useState(null);

    useEffect(() => {
        const savedDownload = localStorage.getItem('download');
        if (savedDownload) {
            setDownloadFile(JSON.parse(savedDownload));
        } else {
            try {
                const download = fileStore.getFile('download');
                if (download) {
                    const teamString = JSON.stringify(download, null, 2);
                    localStorage.setItem('download', teamString);
                    setDownloadFile(download);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }, [fileStore]);


    if (!downloadFile) {
        return <Loading />
    } else {
        downloadFile[language][0]['dataRules'].forEach((rule, index) => {
            rules.push(
                <li key={rule} className="is-size-6 m-4">{rule}</li>
            );
        });

        downloadFile[language][0]['dataAccess'].forEach((rule, index) => {
            access.push(
                <li key={rule} className="is-size-6 m-4">{rule}</li>
            );
        });

        downloadFile[language][0]['fields'].forEach((field, index) => {
            fields.push(
                <ol key={field} className="is-size-6 ml-0">{field}</ol>
            );
        });
    }

    let imageConsentPath = language == 'fr' ? "Utilisationd'images_Consentement_SafeRespirator_Basededonnées.pdf" : 'ImageUse_Consent_SafeRespirator_Dataset.pdf'
    let LicenseAgreementPath = language == 'fr' ? "Licence_d'accès_SafeRespirator_Base_de_données.pdf": 'License_Agreement_SafeRespirator_Dataset.pdf'

    return (
        <>
            <section className="section primary-color">
                <NavBar />
            </section>
            <div className="card radius-0  primary-color pt-2 pb-2">
                <div className="is-size-1 has-text-centered has-text-weight-bold pt-6 m-6 has-text-white is-relative">{t('download_title')}</div>
                <div className="is-size-4 has-text-centered m-4 has-text-white is-relative pb-6">
                    {t('download_desc')}</div>
            </div>

            <div className="pt-4 pb-4">
                <p className="is-size-1 has-text-centered has-text-black pt-4 text-underlined">{t('dataset_usage')}</p>
            </div>

            <div className="content m-6">
                <ol type="1">
                    {rules}
                </ol>
            </div>

            <p className='has-text-centered is-size-6 mt-5 has-text-weight-bold'>{t('citation_desc')}</p>

            <div className='is-flex is-justify-content-center m-0'>
                <div className="card pt-4 pb-4 has-text-weight-bold has-text-centered m-6 has-background-light m-0" style={{ width: "1000px" }}>
                    <div className='m-6'>{downloadFile[language][0]['citation']}</div>
                    <button className="button primary-color is-pulled-right mx-4" onClick={() => navigator.clipboard.writeText(downloadFile[language][0]['citation'])}>
                        <span className="icon is-small">
                            <box-icon color="white" name='copy-alt'></box-icon>
                        </span>
                    </button>
                </div>
            </div>

            <div className="pt-4 pb-4">
                <p className="is-size-1 has-text-centered has-text-black pt-4 text-underlined">{t('dataset_access')}</p>
            </div>

            <div className="content m-6">
                <ol type="1">
                    {access}
                </ol>
            </div>

            <div className='is-flex is-justify-content-center m-6'>
                <div className="columns pr-3">
                    <div className="column">

                        <button className="button is-large">
                            <a className="has-text-primary-color" href={`${process.env.PUBLIC_URL}/pdf/${LicenseAgreementPath}`} download={LicenseAgreementPath}>
                                {t('download_license')}</a>
                        </button>
                    </div>
                    <div className="column">

                        <button className="button is-large">
                            <a className="has-text-primary-color" href={`${process.env.PUBLIC_URL}/pdf/${imageConsentPath}`} download={imageConsentPath}>
                                {t('download_image')}</a>
                        </button>
                    </div>
                </div>
            </div>


            <div className="content m-6">
                <ol type="1">
                    {fields}
                </ol>
            </div>

            <div className='is-flex is-justify-content-center m-6'>
                <div className="columns pr-3">
                    <div className="column">
                        <button className="button button-extra-large">
                            <a className="has-text-primary-color is-size-6" href="mailto:yacine_yaddaden@uqar.ca">
                                <box-icon class="mr-2 mb-0" color="#026aff" type='solid' name='envelope'></box-icon><br />Yacine Yaddaden<br />yacine_yaddaden@uqar.ca</a>
                        </button>
                    </div>

                    <div className="column">
                        <button className="button button-extra-large">
                            <a className="has-text-primary-color is-size-6" href="mailto:jean_brousseau@uqar.ca">
                                <box-icon class="mr-2 mb-0" color="#026aff" type='solid' name='envelope'></box-icon><br />Jean Brousseau<br />jean_brousseau@uqar.ca</a>
                        </button>
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )
}

export default Download;
