
import React, { useState, useEffect } from 'react';

import useStore from '../data/useStore';

import 'boxicons';
import { useTranslation } from 'react-i18next';

import PersonCard from '../components/personCard'
import Loading from '../components/loading'



function Person() {
    const { t, i18n } = useTranslation();
    const fileStore = useStore();
    const [teamFile, setTeamFile] = useState(null);

    let searchers = [];
    let professors = [];
    let students = [];

    let language = i18n.language;


    useEffect(() => {
        const savedTeam = localStorage.getItem('team');
        if (savedTeam) {
            setTeamFile(JSON.parse(savedTeam));
        } else {
            try {
                const team = fileStore.getFile('team');
                if (team) {
                    const teamString = JSON.stringify(team, null, 2);
                    localStorage.setItem('team', teamString);
                    setTeamFile(team);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }, [fileStore]);

    if (!teamFile) {
        return <Loading/>
    } else {
        teamFile[language][0]['searchers'].forEach((person, index) => {
            searchers.push(<PersonCard person={person} index={index} key={index}/>);
        });

        teamFile[language][0]['professors'].forEach((person, index) => {

            professors.push(<PersonCard person={person} index={index} key={index}/>);
        });

        teamFile[language][0]['students'].forEach((person, index) => {
            students.push(<PersonCard person={person} index={index} key={index}/>);
        });
    }

    return (
        <>
            <div className=""><br></br>

                <div className="is-size-1 has-text-centered has-text-white primary-color">{t('researchers')}</div>
                <div className="columns is-multiline is-justify-content-center m-6">
                    {searchers}
                </div>

                <div className="is-size-1 has-text-centered has-text-white primary-color">{t('professors')}</div>
                <div className="columns is-multiline is-justify-content-center m-6">
                    {professors}
                </div>

                <div className="is-size-1 has-text-centered has-text-white primary-color">{t('students')}</div>
                <div className="columns is-multiline is-justify-content-center m-6">
                    {students}
                </div>

            </div>
        </>

    )
}

export default Person;

